import React from 'react';
import Challenges from "../Challenges";
const YourChallenges = () => {
    return (
        <>
            <Challenges yourChallenges={true}></Challenges>
        </>
    );
};

export default YourChallenges;
